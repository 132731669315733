import(/* webpackMode: "eager" */ "/app/apps/frontend/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/apps/frontend/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/apps/frontend/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/apps/frontend/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/app/globals.css");
import(/* webpackMode: "eager" */ "/app/apps/frontend/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/app/apps/frontend/src/components/ScrollTo/ScrollTo.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/aceternity/sparkles.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/DataTable/data-table-pagination.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/DataTable/data-table.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/Dropzone/Dropzone.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/Hexagon/Hexagon.css");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/HoneycombGrid/HoneycombGrid.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/Icons/Logo/Logo.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ScrollAreaPatched/scroll-area-patched.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/accordion.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/alert-dialog.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/aspect-ratio.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/avatar.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/calendar.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/checkbox.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/collapsible.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/command.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/dialog.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/dropdown-menu.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/form.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/hover-card.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/label.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/popover.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/progress.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/radio-group.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/resizable.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/scroll-area.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/select.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/separator.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/sheet.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/sonner.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/switch.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/tabs.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/components/ui/tooltip.tsx");
import(/* webpackMode: "eager" */ "/app/packages/ui-lib/src/styles.css")